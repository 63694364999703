<template>
    <div class="auth-box-wrapper">
        <div class="auth-box shadow-lg border">
            <slot/>
        </div>
    </div>
</template>

<script setup></script>

<style lang="scss">
    .auth-box-wrapper{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .auth-box{
        width: 100%;
        max-width: 500px;
        padding: 20px;
        border-radius: 10px;
        background-color: rgba(var(--background-color),0.8);
    }
</style>